<template>
  <div v-if="client">
    <div class="home__title__bottom">
      <div class="home__title__logo"><img :src="img" alt="logo"></div>
    </div>
    <div class="container">
      <ul class="home__menu">
        <li
          v-for="(homItem, index) in homItems"
          :key="index"
          @click.prevent="foo(index)"
          >
          <router-link :to="homItem.url">
            <div class="menu-icon" v-bind:class="homItem.img"></div>
            <p>{{homItem.text}}</p>
          </router-link>
        </li>
      </ul>
    </div>
    <Popup :popup="popup" @close="popup.fade = !popup.fade" />
  </div>
</template>


<style lang="scss">
  @import '../assets/home.css';
</style>

<script>
import Popup from '@/components/app/Popup'



export default {

  data: () => ({
    popup: {fade: false, mod: 'Home'},
    client: '',
    img: 'https://crm-core.soft-pro.com/img/user-img.svg',

    homItems: [
      {url: '/quotes', img: 'menu-quotation', text: 'Мої котирування'},
      {url: '/orders', img: 'menu-agreements', text: 'Мої угоди'},
      {url: '/calendar', img: 'menu-calendar', text: 'Планер'},
      {url: '/chat', img: 'menu-chat', text: 'Мої чати'},
      {url: '/statistics', img: 'menu-statistics', text: 'Статистика'},
      {url: '', img: 'menu-directory', text: 'Довідник'},
      {url: '/settings', img: 'menu-settings', text: 'Мої налаштування'},
    ],
    globalTitle: [
      {title: 'РіоТранс CRM', info: 'USER1234_QWErty'}
    ],
  }),


  created() {

  },


  async mounted(){
    //get current
    if(this.$root.app == 'SoftProCrmAppRioTransTest' || this.$root.app == 'SoftProCrmAppRioTrans'){
      this.homItems[0].text = this.$root.langText.menu.quotes
      this.homItems[1].text = this.$root.langText.menu.order
    } else{
      this.homItems[0].text = this.$root.langText.menu.quotesOther
      this.homItems[1].text = this.$root.langText.menu.orderOther
    }
    this.homItems[2].text = this.$root.langText.menu.planer
    this.homItems[3].text = this.$root.langText.menu.chat
    this.homItems[4].text = this.$root.langText.menu.statistics
    this.homItems[5].text = this.$root.langText.menu.menuDirectory
    this.homItems[6].text = this.$root.langText.menu.settings
    if(this.$root.app == "SoftProCrmAppVedanta"){
      this.homItems.splice(0, 1)
      this.homItems.splice(1, 0, {text: this.$root.langText.menu.leads, url: '/leads', img: 'menu-quotation'})
    }
    const res = await this.$store.dispatch('getCurrentUser');
    this.client = res.data.detail;
    if(this.client){
      this.img = this.client.img_url
      this.globalTitle[0].title = this.client.name
      this.globalTitle[0].info = this.client.login
      this.$root.title = this.globalTitle
    }
  

  },
  methods: {
    foo(data) {
      if(this.$root.app == 'SoftProCrmAppRioTransTest' || this.$root.app == 'SoftProCrmAppRioTrans'){
        if(data == 5){
          this.popup.fade = !this.popup.fade
        }
      } else{
        if(data == 4){
          this.popup.fade = !this.popup.fade
        }
      }
    }
  },
  components: {
    Popup
  },

  metaInfo(){
    return {
      title: this.$title(this.$root.langText.menu.home),
    }
  },
  
}
</script>
